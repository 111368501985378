





































































.main_container_for_correction {
  .every_card {
    padding: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .name {
      color: #777;
      text-transform: capitalize;
    }
  }
  .pre_or_post_container {
    display: flex;
    gap: 10px;
    .test_type {
      background: #87cb16;
      padding: 8px 20px;
      border-radius: 5px;
      color: #fff;
      border: 1px solid #87cb16;
      &.no_test {
        opacity: 0.5;
        cursor: no-drop;
      }
    }
  }
}
